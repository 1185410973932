<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Start Conversation
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                @click.native="shallShowActiveChatContactSidebar=true"
              />
              <h6 class="mb-0">
                {{ activeChat.contact.fullName }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStore from './chatStore'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup() {
    if (!store.hasModule('store-chat')) store.registerModule('store-chat', chatStore)
    onUnmounted(() => {
      if (store.hasModule('store-chat')) store.unregisterModule('store-chat')
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    const chatsContacts = ref([])
    const contacts = ref([])

    const profileUserData = ref({})
    const profileUserDataMinimal = ref({})

    const previousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    const dayBeforePreviousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 2)

    /* eslint-disable global-require */
    const chatMockupData = {
      profileUser: {
        id: 11,
        avatar: require('@/assets/images/avatars/8.png'),
        fullName: 'John Doe',
        role: 'admin',
        about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
        status: 'online',
        settings: {
          isTwoStepAuthVerificationEnabled: true,
          isNotificationsOn: false,
        },
      },
      contacts: [
        {
          id: 1,
          fullName: '6Kokoa',
          role: 'Frontend Developer',
          about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
          avatar: require('@/assets/images/avatars/1.png'),
          status: 'offline',
        },
        {
          id: 2,
          fullName: 'Adisorn.Ko',
          role: 'UI/UX Designer',
          about:
        'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
          avatar: require('@/assets/images/avatars/2.png'),
          status: 'busy',
        },
        {
          id: 3,
          fullName: '9Kokoa',
          role: 'Town planner',
          about:
        'Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.',
          avatar: require('@/assets/images/avatars/3.png'),
          status: 'away',
        },
        {
          id: 4,
          fullName: 'User.Test',
          role: 'Data scientist',
          about:
        'Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.',
          avatar: require('@/assets/images/avatars/4.png'),
          status: 'online',
        },
      ],
      chats: [
        {
          id: 1,
          userId: 2,
          unseenMsgs: 0,
          chat: [
            {
              message: 'Hi',
              time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'Hello. How can I help You?',
              time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
              senderId: 2,
            },
            {
              message: 'Can I get details of my last transaction I made last month?',
              time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'We need to check if we can provide you such information.',
              time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
              senderId: 2,
            },
            {
              message: 'I will inform you as I get update on this.',
              time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)',
              senderId: 2,
            },
            {
              message: 'If it takes long you can mail me at my mail address.',
              time: dayBeforePreviousDay,
              senderId: 11,
            },
          ],
        },
        {
          id: 2,
          userId: 1,
          unseenMsgs: 1,
          chat: [
            {
              message: "How can we help? We're here for you!",
              time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?',
              time: 'Mon Dec 10 2018 07:45:23 GMT+0000 (GMT)',
              senderId: 1,
            },
            {
              message: 'It should be Bootstrap 4 compatible.',
              time: 'Mon Dec 10 2018 07:45:55 GMT+0000 (GMT)',
              senderId: 1,
            },
            {
              message: 'Absolutely!',
              time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'Modern admin is the responsive bootstrap 4 admin template.!',
              time: 'Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'Looks clean and fresh UI.',
              time: 'Mon Dec 10 2018 07:46:23 GMT+0000 (GMT)',
              senderId: 1,
            },
            {
              message: "It's perfect for my next project.",
              time: 'Mon Dec 10 2018 07:46:33 GMT+0000 (GMT)',
              senderId: 1,
            },
            {
              message: 'How can I purchase it?',
              time: 'Mon Dec 10 2018 07:46:43 GMT+0000 (GMT)',
              senderId: 1,
            },
            {
              message: 'Thanks, from ThemeForest.',
              time: 'Mon Dec 10 2018 07:46:53 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'I will purchase it for sure. 👍',
              time: previousDay,
              senderId: 1,
            },
          ],
        },
      ],
    }
    /* eslint-enable */

    const fetchChatAndContacts = () => {
      const cc = chatMockupData.chats
        .map(chat => {
          const contact = chatMockupData.contacts.find(c => c.id === chat.userId)
          contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
          return contact
        })
        .reverse()
      chatsContacts.value = cc

      contacts.value = chatMockupData.contacts

      const profile = {
        id: chatMockupData.profileUser.id,
        avatar: chatMockupData.profileUser.avatar,
        fullName: chatMockupData.profileUser.fullName,
        status: chatMockupData.profileUser.status,
      }

      profileUserDataMinimal.value = profile

      // store.dispatch('app-chat/fetchChatsAndContacts')
      //   .then(response => {
      //     chatsContacts.value = response.data.chatsContacts
      //     contacts.value = response.data.contacts
      //     // eslint-disable-next-line no-use-before-define
      //     profileUserDataMinimal.value = response.data.profileUser
      //   })
    }

    fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = userId => {
      // Reset send message input value
      chatInputMessage.value = ''

      // Convert Id to number
      const uId = Number(userId)

      const chat = chatMockupData.chats.find(c => c.userId === uId)
      if (chat) chat.unseenMsgs = 0

      const responseData = {
        chat,
        contact: chatMockupData.contacts.find(c => c.id === uId),
      }

      activeChat.value = responseData
      // Set unseenMsgs to 0
      const contact = chatsContacts.value.find(c => c.id === uId)
      if (contact) contact.chat.unseenMsgs = 0

      // Scroll to bottom
      nextTick(() => { scrollToBottomInChatLog() })

      // store.dispatch('app-chat/getChat', { userId })
      //   .then(response => {
      //     activeChat.value = response.data

      //     // Set unseenMsgs to 0
      //     const contact = chatsContacts.value.find(c => c.id === userId)
      //     if (contact) contact.chat.unseenMsgs = 0

      //     // Scroll to bottom
      //     nextTick(() => { scrollToBottomInChatLog() })
      //   })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        contactId: activeChat.value.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: profileUserDataMinimal.value.id,
        message: chatInputMessage.value,
      }
      store.dispatch('app-chat/sendMessage', payload)
        .then(response => {
          const { newMessageData, chat } = response.data

          // ? If it's not undefined => New chat is created (Contact is not in list of chats)
          if (chat !== undefined) {
            activeChat.value = { chat, contact: activeChat.value.contact }
            chatsContacts.value.push({
              ...activeChat.value.contact,
              chat: {
                id: chat.id,
                lastMessage: newMessageData,
                unseenMsgs: 0,
              },
            })
          } else {
            // Add message to log
            activeChat.value.chat.chat.push(newMessageData)
          }

          // Reset send message input value
          chatInputMessage.value = ''

          // Set Last Message for active contact
          const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
          contact.chat.lastMessage = newMessageData

          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      profileUserData.value = chatMockupData.profileUser
      shallShowUserProfileSidebar.value = true

      // store.dispatch('app-chat/getProfileUser')
      //   .then(response => {
      //     profileUserData.value = response.data
      //     shallShowUserProfileSidebar.value = true
      //   })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
